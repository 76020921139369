import * as React from 'react';

const Calculator = ({
    buyButtonText,
    buyButtonLink,
    sellButtonText,
    sellButtonLink,
    balanceText,
    balanceTotal,
    balanceTotalNumber,
    currentStep
}) => {
    return (
        <>
            <h3 className="title">What's your decision?</h3>
            <div className="calculator">
                <div className="calculator__inner">
                    <h4 className="calculator__title">
                        {balanceText}&nbsp;
                        <span
                            data-type="animated-show"
                            data-duration="0.5"
                            data-delay="1"
                            data-count-type="count"
                            data-count-delay="1.5"
                            data-count-finish={balanceTotalNumber}
                            dangerouslySetInnerHTML={{
                                __html: balanceTotal
                            }}
                        />
                    </h4>
                    <h5 className="calculator__subtitle">How much of your capital is used in the trade?</h5>
                    <div className="ui-radiogroup">
                        <div className="ui-radio">
                            <input type="radio" name="deposit" value="100" defaultChecked />
                            <span>$100</span>
                        </div>
                        <div className="ui-radio">
                            <input type="radio" name="deposit" value="200" />
                            <span>$200</span>
                        </div>
                        <div className="ui-radio">
                            <input type="radio" name="deposit" value="500" />
                            <span>$500</span>
                        </div>
                        <div className="ui-radio">
                            <input type="radio" name="deposit" value="1000" />
                            <span>$1000</span>
                        </div>
                    </div>
                    <div className="ui-slider calculator__slider">
                        <span className="ui-slider__label">Leverage used in the trade</span>
                        <div className="ui-slider__control">
                            <input
                                type="range"
                                min="5"
                                max="100"
                                step="5"
                                defaultValue="50"
                            />
                            <div className="ui-slider__control__empty"/>
                            <div className="ui-slider__control__labels">
                                <div className="ui-slider__control__labels__item">5x</div>
                                <div className="ui-slider__control__labels__item">25x</div>
                                <div className="ui-slider__control__labels__item">50x</div>
                                <div className="ui-slider__control__labels__item">75x</div>
                                <div className="ui-slider__control__labels__item">100x</div>
                            </div>
                        </div>
                    </div>
                    <h4 className="calculator__power">
                        Your buying/selling power: <span id="power">$5,000</span>
                    </h4>
                    <div className="calculator__buttons">
                        <div
                            data-to={buyButtonLink}
                            data-current-step={currentStep}
                            data-calculator-button="yes"
                            className="ui-button -green"
                            data-type="animated-show"
                            data-animation="scaleUp"
                            data-duration="0.5"
                            data-delay="4"
                            id="buy"
                        ><span>{buyButtonText}</span></div>
                        <div
                            data-to={sellButtonLink}
                            data-current-step={currentStep}
                            data-calculator-button="yes"
                            className="ui-button -red"
                            data-type="animated-show"
                            data-animation="scaleUp"
                            data-duration="0.5"
                            data-delay="4.5"
                            id="sell"
                        ><span>{sellButtonText}</span></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Calculator