import * as React from 'react';
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import Layout from "../layout/layout"
import Calculator from "../calculator/calculator"
import {
    MouseParallaxChild,
    MouseParallaxContainer
} from "react-parallax-mouse"
import Settings from "../../settings.js"

const QuestionLayout = ({
    backgroundColor,
    additClass,
    contentHTML,
    additHTML,
    buyButtonText,
    buyButtonLink,
    sellButtonText,
    sellButtonLink,
    balanceText,
    currentStep,
    images
}) => {
    let currentBalance, currentBalanceNumber;
    if (typeof window !== 'undefined') {
        if (
            localStorage.getItem('quiz-current-balance') !== '' &&
            localStorage.getItem('quiz-current-balance') !== 'null'
        ) {
            currentBalance = '<i>$</i><span>' + parseFloat(localStorage.getItem('quiz-current-balance')).toLocaleString('en') + '</span>';
            currentBalanceNumber = parseFloat(localStorage.getItem('quiz-current-balance'));

            return (
                <Layout>
                    <Helmet>
                        <body className={"-" + backgroundColor + " " + additClass} />
                    </Helmet>
                    <MouseParallaxContainer
                        enabled={Settings.parallaxOn}
                        className={"screen -question -" + backgroundColor + " " + additClass}
                    >
                        <div
                            className="container"
                            id="swiper"
                        >
                            <div className="screen__content">
                                <div className="screen__content__left"
                                    data-type="animated-show"
                                    data-duration="0.5"
                                    data-delay="2.5"
                                >
                                    <div className={"screen__content__left__inner " + additClass}>
                                        <div className="mobile-advice">
                                            <div className="mobile-advice__item -left">
                                                Swipe left to <b>Buy</b>
                                            </div>
                                            <div className="mobile-advice__item -right">
                                                Swipe right to <b>Sell</b>
                                            </div>
                                            <div className="mobile-advice__button">Okay</div>
                                        </div>
                                        <p className="ui-paragraph"
                                            data-type="animated-show"
                                            data-duration="0.5"
                                            data-delay="3"
                                            data-animation="translateY"
                                            dangerouslySetInnerHTML={{
                                                __html: contentHTML
                                            }}
                                        />
                                        {additHTML &&
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: additHTML
                                                }}
                                            />
                                        }
                                    </div>
                                </div>
                                <div className="screen__content__right"
                                    data-type="animated-show"
                                    data-duration="0.5"
                                    data-delay="1"
                                    data-animation="scaleDown"
                                >
                                    <Calculator
                                        buyButtonText={buyButtonText}
                                        buyButtonLink={buyButtonLink}
                                        sellButtonText={sellButtonText}
                                        sellButtonLink={sellButtonLink}
                                        balanceText={balanceText}
                                        balanceTotal={currentBalance}
                                        balanceTotalNumber={currentBalanceNumber}
                                        currentStep={currentStep}
                                    ></Calculator>
                                </div>
                            </div>
                        </div>
                        {images.map.show === true &&
                            <MouseParallaxChild
                                className="screen__map"
                                factorX={images.map.factor}
                                factorY={images.map.factor}
                            >
                                <div
                                    data-type="animated-show"
                                    data-duration="0.1"
                                    data-delay="0.05"
                                />
                            </MouseParallaxChild>
                        }
                        {images.washingtonTop.show === true &&
                            <MouseParallaxChild
                                className="screen__washington"
                                factorX={images.washingtonTop.factor}
                                factorY={images.washingtonTop.factor}
                            >
                                <div
                                    className="-top"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.1"
                                />
                            </MouseParallaxChild>
                        }
                        {images.dotsLeft.show === true &&
                            <MouseParallaxChild
                                className="screen__dots"
                                factorX={images.dotsLeft.factor}
                                factorY={images.dotsLeft.factor}
                            >
                                <div
                                    className="-left"
                                    data-type="animated-show"
                                    data-duration="0.1"
                                    data-delay="0.15"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartDown.show === true &&
                            <MouseParallaxChild
                                className="screen__chart"
                                factorX={images.chartDown.factor}
                                factorY={images.chartDown.factor}
                            >
                                <div
                                    className="-down"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.2"
                                />
                            </MouseParallaxChild>
                        }
                        {images.dollarSmall.show === true &&
                            <MouseParallaxChild
                                className="screen__dollar-small"
                                factorX={images.dollarSmall.factor}
                                factorY={images.dollarSmall.factor}
                            >
                                <div
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.25"
                                />
                            </MouseParallaxChild>
                        }
                        {images.bitcoin.show === true &&
                            <MouseParallaxChild
                                className="screen__bitcoin"
                                factorX={images.bitcoin.factor}
                                factorY={images.bitcoin.factor}
                            >
                                <div
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.3"
                                />
                            </MouseParallaxChild>
                        }
                        {images.plusesHorizontal.show === true &&
                            <MouseParallaxChild
                                className="screen__pluses"
                                factorX={images.plusesHorizontal.factor}
                                factorY={images.plusesHorizontal.factor}
                            >
                                <div
                                    className="-horizontal"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.35"
                                />
                            </MouseParallaxChild>
                        }
                        {images.plusesHorizontalGreen.show === true &&
                            <MouseParallaxChild
                                className="screen__pluses"
                                factorX={images.plusesHorizontal.factor}
                                factorY={images.plusesHorizontal.factor}
                            >
                                <div
                                    className="-horizontal -green"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.35"
                                />
                            </MouseParallaxChild>
                        }
                        {images.washingtonBottom.show === true &&
                            <MouseParallaxChild
                                className="screen__washington"
                                factorX={images.washingtonBottom.factor}
                                factorY={images.washingtonBottom.factor}
                            >
                                <div
                                    className="-bottom"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.4"
                                />
                            </MouseParallaxChild>
                        }
                        {images.dollarBig.show === true &&
                            <MouseParallaxChild
                                className="screen__dollar-big"
                                factorX={images.dollarBig.factor}
                                factorY={images.dollarBig.factor}
                            >
                                <div
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.45"
                                />
                            </MouseParallaxChild>
                        }
                        {images.plusesVertical.show === true &&
                            <MouseParallaxChild
                                className="screen__pluses"
                                factorX={images.plusesVertical.factor}
                                factorY={images.plusesVertical.factor}
                            >
                                <div
                                    className="-vertical"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.5"
                                />
                            </MouseParallaxChild>
                        }
                        {images.plusesVerticalGreen.show === true &&
                            <MouseParallaxChild
                                className="screen__pluses"
                                factorX={images.plusesVertical.factor}
                                factorY={images.plusesVertical.factor}
                            >
                                <div
                                    className="-vertical -green"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.5"
                                />
                            </MouseParallaxChild>
                        }
                        {images.dotsRight.show === true &&
                            <MouseParallaxChild
                                className="screen__dots"
                                factorX={images.dotsRight.factor}
                                factorY={images.dotsRight.factor}
                            >
                                <div
                                    className="-right"
                                    data-type="animated-show"
                                    data-duration="0.1"
                                    data-delay="0.55"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartUp.show === true &&
                            <MouseParallaxChild
                                className="screen__chart"
                                factorX={images.chartUp.factor}
                                factorY={images.chartUp.factor}
                            >
                                <div
                                    className="-up"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.6"
                                />
                            </MouseParallaxChild>
                        }
                        {images.questionMarkTop.show === true &&
                            <MouseParallaxChild
                                className="screen__question"
                                factorX={images.questionMarkTop.factor}
                                factorY={images.questionMarkTop.factor}
                            >
                                <div
                                    className="-top"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.1"
                                />
                            </MouseParallaxChild>
                        }
                        {images.questionMarkBottom.show === true &&
                            <MouseParallaxChild
                                className="screen__question"
                                factorX={images.questionMarkBottom.factor}
                                factorY={images.questionMarkBottom.factor}
                            >
                                <div
                                    className="-bottom"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.4"
                                />
                            </MouseParallaxChild>
                        }
                        {images.satoshiTop.show === true &&
                            <MouseParallaxChild
                                className="screen__satoshi"
                                factorX={images.satoshiTop.factor}
                                factorY={images.satoshiTop.factor}
                            >
                                <div
                                    className="-top"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.1"
                                />
                            </MouseParallaxChild>
                        }
                        {images.bankTop.show === true &&
                            <MouseParallaxChild
                                className="screen__bank"
                                factorX={images.bankTop.factor}
                                factorY={images.bankTop.factor}
                            >
                                <div
                                    className="-top"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.1"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartArrowGreenRedTop.show === true &&
                            <MouseParallaxChild
                                className="screen__chart-arrow"
                                factorX={images.chartArrowGreenRedTop.factor}
                                factorY={images.chartArrowGreenRedTop.factor}
                            >
                                <div
                                    className="-top -green-red"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.1"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartArrowGreenTop.show === true &&
                            <MouseParallaxChild
                                className="screen__chart-arrow"
                                factorX={images.chartArrowGreenTop.factor}
                                factorY={images.chartArrowGreenTop.factor}
                            >
                                <div
                                    className="-top -green"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.1"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartArrowYellowTop.show === true &&
                            <MouseParallaxChild
                                className="screen__chart-arrow"
                                factorX={images.chartArrowYellowTop.factor}
                                factorY={images.chartArrowYellowTop.factor}
                            >
                                <div
                                    className="-top -yellow"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.1"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartGreenUp.show === true &&
                            <MouseParallaxChild
                                className="screen__chart"
                                factorX={images.chartGreenUp.factor}
                                factorY={images.chartGreenUp.factor}
                            >
                                <div
                                    className="-up -green"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.6"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartGreenDown.show === true &&
                            <MouseParallaxChild
                                className="screen__chart"
                                factorX={images.chartGreenDown.factor}
                                factorY={images.chartGreenDown.factor}
                            >
                                <div
                                    className="-down -green"
                                    data-type="animated-show"
                                    data-animation="scaleDown"
                                    data-duration="0.1"
                                    data-delay="0.2"
                                />
                            </MouseParallaxChild>
                        }
                        {images.bitcoinBottom.show === true &&
                            <MouseParallaxChild
                                className="screen__bitcoin"
                                factorX={images.bitcoinBottom.factor}
                                factorY={images.bitcoinBottom.factor}
                            >
                                <div
                                    className="-bottom"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.3"
                                />
                            </MouseParallaxChild>
                        }
                        {images.satoshiBottom.show === true &&
                            <MouseParallaxChild
                                className="screen__satoshi"
                                factorX={images.satoshiBottom.factor}
                                factorY={images.satoshiBottom.factor}
                            >
                                <div
                                    className="-bottom"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.4"
                                />
                            </MouseParallaxChild>
                        }
                        {images.bankBottom.show === true &&
                            <MouseParallaxChild
                                className="screen__bank"
                                factorX={images.bankBottom.factor}
                                factorY={images.bankBottom.factor}
                            >
                                <div
                                    className="-bottom"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.4"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartArrowGreenRedBottom.show === true &&
                            <MouseParallaxChild
                                className="screen__chart-arrow"
                                factorX={images.chartArrowGreenRedBottom.factor}
                                factorY={images.chartArrowGreenRedBottom.factor}
                            >
                                <div
                                    className="-bottom -green-red"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.4"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartArrowGreenBottom.show === true &&
                            <MouseParallaxChild
                                className="screen__chart-arrow"
                                factorX={images.chartArrowGreenBottom.factor}
                                factorY={images.chartArrowGreenBottom.factor}
                            >
                                <div
                                    className="-bottom -green"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.4"
                                />
                            </MouseParallaxChild>
                        }
                        {images.chartArrowYellowBottom.show === true &&
                            <MouseParallaxChild
                                className="screen__chart-arrow"
                                factorX={images.chartArrowYellowBottom.factor}
                                factorY={images.chartArrowYellowBottom.factor}
                            >
                                <div
                                    className="-bottom -yellow"
                                    data-type="animated-show"
                                    data-animation="scaleUp"
                                    data-duration="0.1"
                                    data-delay="0.4"
                                />
                            </MouseParallaxChild>
                        }
                    </MouseParallaxContainer>
                </Layout>
            )
        }
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default QuestionLayout
